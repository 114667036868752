import { Environment } from '../models/environment';
import { NgEnvironment } from '@scaliolabs/baza-core-ng';

export const environment: Environment = {
    ngEnv: NgEnvironment.Stage,
    enableAngularProduction: true,
    apiEndpoint: 'https://baza-api.hotfix.scaliolabs.com',
    sentry: {
        dsn: 'https://a042eb7c07f04ea0b1f0664a952711de@sentry.production.scaliolabs.com/25',
        browserOptions: {
            environment: NgEnvironment.Stage,
        },
    },
    recaptchaSiteKey: '6Leziy4nAAAAADfpZCXP-K8dkbRz2EgAsE9eAsgL',
};
